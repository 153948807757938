import React from "react";

import { MemoryRouter as MR, BrowserRouter as BR } from "react-router-dom";
import Global from "style";
import { Full } from "components/container";
import Loading from "components/loading/Loading";
import { CATCHPHRASE } from "constants";

const Home = React.lazy(() => import("./home"));

const App = () => {
  return (
    <Full>
      <React.Suspense fallback={<Loading title={CATCHPHRASE} />}>
        <Home />
      </React.Suspense>
    </Full>
  );
};

export const MemoryRouter = ({ url }) => (
  <MR initialEntries={[url || "/"]}>
    <Global />
    <App />
  </MR>
);

export const BrowserRouter = ({ url }) => (
  <BR initialEntries={[url || "/"]}>
    <Global />
    <App />
  </BR>
);
