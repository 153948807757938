import React from "react";
import { Spin, Flex, Typography } from "antd";
import { FlexCenter } from "components/container";
import Logo from "components/logo/Logo";

const Loading = ({ title, style = {}, ...props }) =>
  title ? (
    <FlexCenter
      className="loading-container"
      full
      absolute
      style={{ background: "#001529", color: "white", ...style }}
    >
      <Flex
        className="loading-flex-parent"
        vertical
        justify="center"
        gap="large"
      >
        <Flex
          className="loading-flex-child"
          vertical
          gap="small"
          align="center"
        >
          <Logo
            vertical
            styles={{ logo: { size: "6x" }, title: { color: "white" } }}
          />
          <Typography.Text style={{ fontSize: 20, color: "white" }}>
            {title}
          </Typography.Text>
        </Flex>
        <Spin size="large" {...props} />
      </Flex>
    </FlexCenter>
  ) : (
    <FlexCenter full style={style}>
      <Spin size="large" {...props} />
    </FlexCenter>
  );

Loading.propTypes = {};

export default Loading;
