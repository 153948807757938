import React from "react";
import styled from "styled-components";
import { Flex, Typography } from "antd";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCanadianMapleLeaf } from "@fortawesome/free-brands-svg-icons/faCanadianMapleLeaf";
import { faCubesStacked } from "@fortawesome/free-solid-svg-icons/faCubesStacked";
import { media } from "style";
const { Title } = Typography;

const Container = styled(Flex)`
  ${media.portable`
    h1 {
      display: none;
    }
    `}

  ${media.desk`
    h2 {
      display: none;
    }
  `}
`;
const Logo = ({
  logo,
  className,
  title = "Modolo",
  short,
  style,
  styles: { logo: logoStyle = {}, title: titleStyle = {} } = {},
  ...props
}) => {
  return React.isValidElement(logo) ? (
    logo
  ) : (
    <Container
      className={className || "logo-container"}
      align="center"
      gap={12}
      style={style}
      {...props}
    >
      {(_.isObject(logo) && logo?.url) || logo?.src ? (
        <img
          src={logo?.url || logo?.src}
          alt={logo?.alt || "Logo"}
          {..._.assign(logo, logoStyle)}
        />
      ) : (
        <FontAwesomeIcon
          {...{
            size: "3x",
            mask: faCanadianMapleLeaf,
            transform: "shrink-10",
            icon: faCubesStacked,
            ...logoStyle,
          }}
        />
      )}
      {title && (
        <Title
          level={1}
          style={{
            margin: 0,
            fontSize: 32,
            ...titleStyle,
          }}
        >
          {title}
        </Title>
      )}
      {short && (
        <Title
          level={2}
          style={{
            margin: 0,
            fontSize: 24,
            ...titleStyle,
          }}
        >
          {short}
        </Title>
      )}
    </Container>
  );
};

Logo.propTypes = {};

export default Logo;
