import { createGlobalStyle } from "styled-components";
import { css } from "styled-components";

export const scroll = css`
  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--ant-color-bg-mask);
    border-radius: 4px;
  }
`;

export default createGlobalStyle`
  body {
    margin: 0;
    padding: 0;       
    height: 100vh;
    
    font-family:"Helvetica Neue", "Helvetica", Arial, "Lucida Grande", sans-serif;
    
    // .ant-layout-header {
    //   height: var(--ant-layout-header-height);
    //   padding: var(--ant-layout-header-padding);
    //   color: var(--ant-layout-header-color);
    //   line-height: var(--ant-layout-header-height);
    //   background: var(--ant-layout-header-bg
    // );
    
    ${scroll}
    
    .ant-flex {
      ${scroll}
    }
    
  
    *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
    
  
  a {
    text-decoration: none;
  }
  
  
  }
`;

export const breakPoints = {
  palm: 588,
  desk: 768,
};

export const media = {
  palm: (...args) => css`
    @media (max-width: ${breakPoints.palm}px) {
      ${css(...args)};
    }
  `,

  portable: (...args) => css`
    @media (max-width: ${breakPoints.desk}px) {
      ${css(...args)};
    }
  `,

  desk: (...args) => css`
    @media (min-width: ${breakPoints.desk + 1}px) {
      ${css(...args)};
    }
  `,
};
