/**
 * A catchphrase for the application.
 * @type {string}
 * @name CATCHPHRASE
 */
export const CATCHPHRASE =
  "... cloud-based data sharing, visualization, and analysis ...";

/**
 * A loading page message.
 * @type {string}
 * @name LOADINGPAGE
 */
export const LOADINGPAGE = "... loading web page ...";

export const VIEWABLE = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "webp",
  "md",
  "json",
  "txt",
  "job",
  "py",
];

export const CODES = ["py", "js"];
